import { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

export default ({ data }) => {
  const filteredSlugs = [];
  const allSlugs = data.photos.nodes[0].photoProjects;
  allSlugs.map(slug => !slug.hidden && filteredSlugs.push(slug.slug.current));
  const randomNum = Math.floor(Math.random() * filteredSlugs.length);
  const randomProject = filteredSlugs[randomNum];

  useEffect(() => {
    navigate(`/photo/${randomProject}`);
  }, [randomProject]);
  return null;
};

export const query = graphql`
  query Index {
    photos: allSanityPhotosPage {
      nodes {
        photoProjects {
          hidden
          slug {
            current
          }
        }
      }
    }
  }
`;
